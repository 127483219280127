import React, { Component } from "react";
import './App.css';


class Homepage extends Component{

//   sendData(data) {
//     this.props.buttonClick(data);
//   }
//   scrollJoin() {
//     document.getElementById("join").scrollIntoView();
//   }
//   scrollWho() {
//     document.getElementById("who").scrollIntoView();
//   }
//   scrollFind() {
//     document.getElementById("place").scrollIntoView();
//   }
//   scrollTeam() {
//     document.getElementById("team").scrollIntoView();
//   }

//   screenWidthBigEnough() {
//     if (window.innerWidth < 500) {
//       return "1vw";
//     } else {
//       return "10vw";
//     }
//   }

//   screenWidthBigEnoughforInterMargin() {
//     if (window.innerWidth < 500) {
//       return "10vw";
//     } else {
//       return "auto";
//     }
//   }

  render() {
    // const screenSizeResult = this.screenWidthBigEnough();

    // const screenInterMarginResult = this.screenWidthBigEnoughforInterMargin();

    return (
      <div>
        <h1
          style={{
          textAlign: "center",
          color: "#5C978D",
          margin: 'auto',
          marginTop: '-1vw',
          marginRight: '1vw',
          position: "dynamic",
          fontFamily: "Josefin Slab",
          fontSize: "100%",
          lineHeight: "2vw",
        }}
        ><span style={{fontStyle: 'italic'}}>under construction, check back later! </span>
         o7
        </h1>
    </div> 
    )
        }}

    export default Homepage;
